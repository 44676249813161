function replaceClass() { 
    let html = document.getElementsByTagName('html')[0];
    html.classList.remove('no-js');
    html.classList.add('js');
} 

function collectAllModules() {
	let modules = document.querySelectorAll('[data-module]');
	
	if (modules.length) {
		modules.forEach((node) => {
			node.getAttribute('data-module')
				.split(' ')
				.forEach((module) => {
                    import(/* webpackChunkName: "js/modules/[request]" */`./modules/${module}`)
                        .then((m) => {
                            console.log(`%c${module} module is loaded.`, 'color: skyblue')
                            m.init(node)
                        })
                        .catch((error) => { 
                            console.log(`%cError in ${module} module!`, 'color: red')
                            console.error(error)
                        });
				});
			// node.removeAttribute('data-module');
		});
	}
}

replaceClass();
collectAllModules();